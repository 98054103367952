
main {
   .main-content {
      max-width: 1270px;
      min-height: 1000px;
      margin: 500px auto 0;
      padding: 0 1rem;
      padding-bottom: 1rem;
      section {
         display: block;
         width: 100%;
         height: 100%;
         padding: 1rem;
         margin-bottom: 2rem;
         & > header {
            font-size: calc(1rem + .5vmin);
            margin-bottom: 2rem;
         }
         & > article {
            margin: 2rem 0;
            & > h2 {
               font-size: calc(1rem + .5vmin);
               font-weight: 500;
               margin-bottom: 1rem;
            }
            & > h3 {
               font-size: calc(1rem + .25vmin);
               font-weight: 500;
               margin-bottom: .5rem;
            }
            & > p {
               font-size: 16px;
               font-weight: 400;
               letter-spacing: 1px;
               margin-bottom: 1rem;
               color: #595959;
            }
            & > ul {
               li {
                  letter-spacing: 1px;
                  margin-bottom: 1rem;
                  margin-left: 1.5rem;
               }
            }
         }
      }
   }
}

.navLink {
   position: relative;
   -webkit-transition: all 0.3s ease;
   -o-transition: all 0.3s ease;
   transition: all 0.3s ease;
   width: 100%;
   max-width: 100px;
   &:focus::after, &:hover::after {
      width: 100%;
   }
   &::after {
      content: "";
      display: block;
      margin-top: 0.875rem;
      height: 3px;
      background-color: #bebbbc;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
   }
}

@media (max-width: 767px) {
	main {
		.main-content {
         padding: 0 .5rem;
         section {
            padding: 1rem .5rem;
         }
      }
	}
}