.contact-form {
   display: flex;
   flex-direction: column;
   width: 100%;
   & > span {
      display: flex;
      width: 100%;
      margin: 1rem 0;
      input[type="text"] {
         width: 100%;
         border: 1px solid lightgrey;
         padding: .5rem;
      }
   }
   textarea {
      border: 1px solid lightgrey;
      padding: .5rem;
   }
}