.content-left {
   & > h1 {
      text-align: left;
      font-size: calc(1rem + 3vmin);
      font-weight: 700;
      padding-bottom: 2rem;
      font-family: 'Bitter', serif;
      position: relative;
      &::after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 80px;
         border-top: 3px solid #73bbc5;
      }
   }
   & > p {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      letter-spacing: 1px;
   }
   & > article {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 2rem;
      margin-top: 3rem;
      & > div {
         &:first-of-type {
            position: relative;
            border-radius: 20px;
            img {
               border-radius: 20px;
               object-fit: cover;
               transform: scale(.8);
               box-shadow: 0 0 10px rgba(0, 0, 0, .2);
               z-index: 1;
            }
         }
         &:last-of-type {
            letter-spacing: 1.25px;
            line-height: 1.7;
            order: -1;
            & > div {
               margin-top: 1rem;
               a {
                  color: #7f00e0;
                  font-size: calc(1rem - .2vmin);
                  letter-spacing: 3px;
                  font-weight: 700;
                  border: 1px solid #7f00e0;
                  text-transform: uppercase;
                  padding: .5rem 2.5rem;
                  border-radius: 4px;
               }
            }
         }
      }
   }
   @media (min-width: 768px) {
      & > h1 {
         max-width: calc(50% - 1rem);
         margin-right: auto;
      }
   }
}

.content-right {
   & > h1 {
      text-align: left;
      font-size: calc(1rem + 3vmin);
      font-weight: 700;
      padding-bottom: 2rem;
      font-family: 'Bitter', serif;
      position: relative;
      &::after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 80px;
         border-top: 3px solid #73bbc5;
      }
   }
   & > p {
      text-align: center;
      font-size: calc(1rem + 1vmin);
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      letter-spacing: 1px;
   }
   & > article {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 2rem;
      margin-top: 3rem;
      & > div {
         &:first-of-type {
            position: relative;
            border-radius: 20px;
            img {
               border-radius: 20px;
               object-fit: cover;
               transform: scale(.8);
               box-shadow: 0 0 10px rgba(0, 0, 0, .2);
               z-index: 1;
            }
         }
         &:last-of-type {
            letter-spacing: 1.25px;
            line-height: 1.7;
            p {
               margin-bottom: 2rem;
            }
            ul {
               li {
                  margin-bottom: 2rem;
                  strong {
                     display: block;
                     text-transform: uppercase;
                  }
               }
            }
            & > div {
               margin-top: 1rem;
               a {
                  color: #7f00e0;
                  font-size: calc(1rem - .2vmin);
                  letter-spacing: 3px;
                  font-weight: 700;
                  border: 1px solid #7f00e0;
                  text-transform: uppercase;
                  padding: .5rem 2.5rem;
                  border-radius: 4px;
               }
            }
         }
      }
   }
   @media (min-width: 768px) {
      & > h1 {
         max-width: calc(50% - 1rem);
         margin-left: auto;
      }
   }
}
