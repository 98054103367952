.avatar-container {
   width: 100%;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   .avatar-content {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 400px;
      max-height: 451px;
      svg[class],
      svg[class] path {
         position: absolute;
         will-change: transform;
         transition: transform .35s ease-out;
      }
		.avatar-eyeballs {
			clip-path: url('#eyes-clip');
		}
      & > svg {
         width: 100%;
         height: 100%;
         max-width: 400px;
         max-height: 451px;
      }
   }
}
@media (max-width: 767px) {
   .avatar-container {
      height: 60vh;
   }
}
