.hero-avatar-right {
   height: 100%;
   min-height: 100vh;
   position: relative;
   & > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      justify-content: space-around;
      align-items: center;
      max-width: 1270px;
      margin: 0 auto;
      padding: 1rem 2rem;
      & > div {
         &:first-of-type {

         }
         &:last-of-type {
            order: -1;
            position: relative;
            font-family: 'Bitter', serif;
            h1 {
               font-size: calc(1rem + 4vmin);
               font-weight: 400;
               color: #0f1b61;
               line-height: 1;
               span,
               strong {
                  font-size: inherit;
               }
            }
            & > div {
               margin-top: 1rem;
               &:first-of-type {
                  font-size: calc(1rem + .9vmin);
                  span {
                     font-size: inherit;
                     &:last-of-type {
                        display: block;
                     }
                  }
                  ul {
                     height: 29px;
                     vertical-align: middle;
                     display: inline-block;
                     overflow: hidden;
                     vertical-align: top;
                     list-style: none;
                     will-change: transform;
                     position: relative;
                     max-width: 460px;
                     width: 100%;
                     li {
                        height: calc(29px + 1.25rem);
                        white-space: nowrap;
                        padding-top: .25rem;
                        margin-bottom: 1rem;
                        font-weight: 700;
                        font-size: calc(1rem + .5vmin);
                        color: #0f1b61;
                        transform-origin: top center;
                        position: absolute;
                        max-width: 460px;
                        width: 100%;
                        transform: translateY(100%);
                        &[aria-label="rotate"] {
                           animation: slideUp 4s infinite;
                        }
                     }
                     @keyframes slideUp {
                        0% {
                           transform: translateY(100%)
                        }
                        25% {
                           transform: translateY(-10%);
                        }
                        50% {
                           transform: translateY(0);
                        }
                        75% {
                           transform: translateY(0);
                        }
                        100% {
                           transform: translateY(-100%);
                        }
                     }
                  }
               }
               &:last-of-type {
                  a {
                     margin: 1rem 0;
                     padding: 1rem;
                     border: 1px solid #0f1b61;
                     border-radius: 10px;
                     font-size: 16px;
                     font-weight: 700;
                     color: #0f1b61;
                     text-transform: uppercase;
                  }
               }
            }
         }
      }
      &::before {
         content: 'Scroll';
         text-transform: uppercase;
         position: absolute;
         bottom: 35%;
         left: 25%;
         transform: translate(-50%, 100%);
         font-family: 'Bitter', serif;
         padding-top: 100px;
      }
      &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 35%;
         left: 25%;
         transform: translate(-50%, calc(100% + 150px));
         border-left: 1px solid #0f1b61;
         height: 500px;
         width: 1px;
      }
   }
}
@media (max-width: 767px) {
   .hero-avatar-right {
      min-height: 50vh;
      & > div {
         &::before {
            bottom: 20%;
         }
         &::after {
            bottom: 20%;
         }
      }
   }
}