
.btn-outline {
   background-color: #fff;
   color: #000;
   min-height: 44px;
   padding: 0 1rem;
   height: 100%;
   border: 1px solid rgba(0, 97, 254, .8);
   border-radius: 4px;
   width: 100%;
   &:hover {
      background-color: rgba(0, 97, 254, 1);
      color: #fff;
   }
}
.btn-dark-outline {
   border-radius: 3px;
   border: 1px solid #000;
   background: none;
   font-size: 14px;
   color: #000 !important;
   padding: .5rem 1.5rem;
   &:hover {
      background-color: #bc2227;
      color: #fff !important;
      border-color: #bc2227;
   }
}
.link-cta {
   font-size: 14px;
   font-weight: 200;
   color: #155A93;
   display: block;
   margin-top: auto;
   padding-top: 1rem;
   &:visited {
      color: #155A93;
   }
   &:hover {
      color: #bc2227;
   }
   &::after {
      content: '➔';
      margin-left: .5rem;
   }
}
.btn-primary {
   display: inline-block;
   position: relative;
   padding: 1rem 1.5rem;
   margin: 1rem 0;
   background-color: rgba(180, 4, 4, 0.9);
   color: #fff;
   font-weight: 700;
   &:hover {
      opacity: .6;
   }
}
.nav-menu-btn { 
   &[aria-expanded="false"] {
      & > span {
         width: 30px;
         height: 24px;
         position: relative;
         display: inline-block;
         & > span {
            width: 30px;
            height: 3px;
            border-radius: 4px;
            background-color: black;
            display: inline-block;
            &::before {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               width: 30px;
               height: 3px;
               border-radius: 4px;
               background-color: black;
            }
            &::after {
               content: '';
               position: absolute;
               bottom: 0;
               left: 0;
               width: 30px;
               height: 3px;
               border-radius: 4px;
               background-color: black;
            }
         }
      }
   }
	&[aria-expanded="true"] {
		& > span {
         width: 30px;
         height: 24px;
         position: relative;
         display: inline-block;
         & > span {
            width: 30px;
            height: 3px;
            border-radius: 4px;
            background-color: transparent;
            display: inline-block;
            &::before {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               width: 30px;
               height: 3px;
               border-radius: 4px;
               background-color: black;
					transform: translate3d(0,10px,0) rotate(45deg);
            }
            &::after {
               content: '';
               position: absolute;
               top: 0;
					bottom: -10px;
               left: 0;
               width: 30px;
               height: 3px;
               border-radius: 4px;
               background-color: black;
					transform: translate3d(0,10px,0) rotate(-45deg);
            }
         }
      }
	}
}
.btn-cta {
   text-align: center;
   font-weight: 500 !important;
   font-size: 15px;
   line-height: 18px;
   letter-spacing: .06em;
   text-transform: uppercase;
   color: #fff !important;
   background: #0652dd;
   border-radius: 100px;
   display: inline-block;
   padding: 10px 20px !important;
   text-decoration: auto;
   text-decoration: auto;
   border: 1px solid #0652dd;
   &:hover {
      opacity: .7;
      transition: all ease .3s;
   }
}